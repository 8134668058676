import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import ajax from '../../Services/fetchService';
import {useUser} from "../../Services/userProvider";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import BusesAPI from "../../apis/managerApi/busAPI";
import Select from "../forms/Select";
import CheckBoxField from "../forms/CheckBoxField";
import Field from "../forms/Field";
import FormContentLoader from "../loaders/FormContentLoader";
import MyButton from "../assets/MyButton";

const EditAvailablity = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {available_id} = useParams();
    const [showLoader, setShowLoader] = useState(false);

    const [availablity, setAvailabliy] = useState({
        bus_id : "", pattern : "", available : "", id : available_id
    });

    const [editing, setEditing] = useState(false);
    const [checked, setChecked] = useState(false);
    const [buses, setBuses] = useState([]);

    const [errors, setErrors] = useState({
        bus_id : "", pattern : "", available : ""
    });

    const fetchBuses = async () => {
        try{
            const data =  await BusesAPI.findAll();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des buses !");
        }
    };

    const fetchAvailability = async id => {
        try{
            const { bus_id, pattern, available } = await BusesAPI.findOneAvailability(id);
            setAvailabliy({ bus_id, pattern, available });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le status démandé !");
            navigate("/manager/buses");
        }
    };


    useEffect(() => {
        fetchBuses();
    },[]);

    useEffect(() => {
        setEditing(true);
        fetchAvailability(available_id);
    },[available_id]);

    const handleChex = () => {
        setChecked(!checked);
        if (!checked === true) {
            setAvailabliy({...availablity, available : 1});
        }else{
            setAvailabliy({...availablity, available : 0});
        }

    };

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAvailabliy({...availablity, [name] : value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
             setShowLoader(true);
             const response = await BusesAPI.updateBusAvailability(available_id, availablity);
             setTimeout(() => setShowLoader(false), 1000);
             toast.success(response.data.message);
             navigate("/manager/buses");
        }catch({response}){
            if (response) {
                const {violations} = response.data;

                if(violations){
                    const apiErrors = {};
                    Object.keys(violations).forEach(key => {
                        apiErrors[key] = violations[key]
                    });

                    setErrors(apiErrors);
                    toast.error("Une erreur est servenue dans le formulaire de la modification du status !");
                }else{
                    toast.error(response.data.message);
                }
            }
        }
    };





    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un status de bus</h5>
                                        <Select name="bus_id" label="Bus" value={availablity.bus_id} error={errors.bus_id} onChange={handleChange}>
                                            {/*<option value="">Choisir un bus...</option>*/}
                                            {buses.map(bus =>
                                                <option key={bus.id} value={bus.id}>
                                                    {bus.bus_code} - Plaque : {bus.bus_numberplate}
                                                </option>)
                                            }
                                        </Select>

                                        <CheckBoxField
                                            name="available"
                                            id="available"
                                            value={availablity.available}
                                            error={errors.available}
                                            label="Disponible ?"
                                            onChange={handleChex}
                                            checked={availablity.available ? "checked" : ""}
                                        />

                                        <div className="col-md-12">
                                            <Field
                                                name="pattern"
                                                error={errors.pattern}
                                                value={availablity.pattern === null ? "" : availablity.pattern}
                                                onChange={handleChange}
                                                label="Motif"
                                            />
                                        </div>
                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/manager/buses" className="btn btn-danger mx-2">Retourner</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditAvailablity;
