import React, { useEffect, useState, useRef } from 'react';
import ajax from '../../Services/fetchService';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Moment from "moment";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useForm} from "react-hook-form";
import BusesAPI from "../../apis/managerApi/busAPI";
import Select from "../forms/Select";
import TableLoader from "../loaders/TableLoader";
import MyButton from "../assets/MyButton";
import Field from "../forms/Field";

const AddAssigngment = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const [loading, setLoading] = useState(true);
    const [buses, setBuses] = useState([]);
    const [vendeurs, setVendeurs] = useState([]);
    const [dailyAssignments, setDailyAssignments] = useState([]);
    const [trajets, setTrajets] = useState([]);
    const [busesHours, setBusesHours] = useState([]);
    const [bus_managers, setBusManagers] = useState([]);
    const [drivers, setDrivers] = useState([]);

    const [assignment, setAssignment] = useState({
        bus_id : "", p_id : "", reservation : 1, genre : 0, busManager : "", busDriver : "",
        busHour : "", reservation_date : "", destination : "",
    });

    const [errors, setErrors] = useState({
        bus_id : "", p_id : "", busManager : "", busDriver : "", busHour : "",
        reservation_date : "", destination : "",
    });

    const fetchDailyAssignments = async () => {
        try{
            const data =  await BusesAPI.findDailyDirectAssignments();
            setDailyAssignments(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des affectations du jours !");
        }
    };

    const fetchTrajets = async () => {
        try{
            const data =  await BusesAPI.findTrajects();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };

    const fetchBusHours = async () => {
        try{
            const data =  await BusesAPI.findBusHours();
            setBusesHours(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des heures de bus !");
        }
    };

    const fetchAvailableDailyBus = async () => {
        try{
            const data =  await BusesAPI.findDailyAvailabilities();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    const fetchVendeurs = async () => {
        try{
            const data =  await BusesAPI.findVendors();
            setVendeurs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des vendeurs !");
        }
    };

    const fetchExcorteurs = async () => {
        try{
            const data =  await BusesAPI.findBusExcortors();
            setBusManagers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des excorteurs !");
        }
    };

    const fetchDrivers = async () => {
        try{
            const data =  await BusesAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };


    useEffect(() => {
        fetchTrajets();
        fetchDailyAssignments();
        fetchBusHours();
        fetchAvailableDailyBus();
        fetchVendeurs();
        fetchExcorteurs();
        fetchDrivers();
    },[]);



    const tooltip = (chaffeuer, escorteur) => (
        <Tooltip className="" id="tooltip">
            <ul className="list-group"><li>Chauffeur: {chaffeuer}</li><li>Escort: {escorteur}</li></ul>
        </Tooltip>
    );


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAssignment({...assignment, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await BusesAPI.createAssignment(assignment);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Affectations ajouté avec succès");
            window.location.reload();
        }catch({response}){
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de l'ajout de l'affectation !");
            }
        }
    };

    let countir = 1;

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h2 className="card-title">Affecter un bus à un point de vente</h2>
                                <div className="row">
                                    <div className="col-md-3">

                                        <Select name="bus_id" label="Bus disponible" value={assignment.bus_id}
                                                error={errors.bus_id} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {buses.map(bus =>
                                                <option key={bus.id} value={bus.bus_id}>
                                                    {bus.bus_code} - {bus.bus_numberplate}
                                                </option>)
                                            }
                                        </Select>

                                        <Select name="destination" label="Destination" value={assignment.destination}
                                                error={errors.destination} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {trajets.map(trajet =>
                                                <option key={trajet.id} value={trajet.id}>{trajet.from} - {trajet.to}</option>)
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-md-3">

                                        {/*<Select name="busHour" label="Heure de bus" value={assignment.busHour}*/}
                                                {/*error={errors.busHour} onChange={handleChange}>*/}
                                            {/*<option value="">Choisir</option>*/}
                                            {/*{busesHours.map(busH =>*/}
                                                {/*<option key={busH.id} value={busH.id}>{busH.bus_hour}</option>)*/}
                                            {/*}*/}
                                        {/*</Select>*/}

                                        <Field
                                            name="busHour"
                                            error={errors.busHour}
                                            value={assignment.busHour}
                                            onChange={handleChange}
                                            label="Heure de bus"
                                            placeholder="Ex: 06h30"
                                            autoComplete="off"
                                        />


                                        <Select name="busDriver" label="Chauffeur" value={assignment.busDriver}
                                                error={errors.busDriver} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {drivers.map(driver =>
                                                <option key={driver.id} value={driver.id}>{driver.name}</option>)
                                            }
                                        </Select>


                                    </div>
                                    <div className="col-md-3">

                                        <Select name="p_id" label="Agence ou vendeur" value={assignment.p_id}
                                                error={errors.p_id} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {vendeurs.map(vendeur =>
                                                <option key={vendeur.id} value={vendeur.id}>
                                                {vendeur.name} |
                                            {vendeur.pos_name}</option>)
                                            }
                                        </Select>

                                        <Select name="busManager" label="Excorteur" value={assignment.busManager}
                                                error={errors.busManager} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {bus_managers.map(gerant =>
                                                <option key={gerant.id} value={gerant.id}>{gerant.name}</option>)
                                            }
                                        </Select>

                                    </div>
                                </div>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/manager/buses" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Total bus direct aujoud'hui: <b className="text-primary">
                                {dailyAssignments ? dailyAssignments.length : 0} </b>
                            </h2>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Nom</th>
                                    <th scope="col">Guichet</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Heure</th>
                                    <th scope="col">Depart</th>
                                    <th scope="col">Agents</th>
                                    <th scope="col">Agence</th>
                                    <th scope="col"><i className="bi bi-gear-fill"></i></th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {dailyAssignments.map((assignment) => (
                                        <tr key={assignment.id}>
                                            <th scope="row">{countir++}</th>
                                            <td>
                                                <b>{assignment.bus_numberplate}, Place: {assignment.bus_number_of_seats}</b>
                                            </td>
                                            <td className="text-primary">{assignment.vendor_name}</td>
                                            <td>{assignment.p_name}</td>
                                            <td>{assignment.to}</td>
                                            <td>{Moment(assignment.created_at).format("HH:mm")}</td>
                                            <td>{assignment.bus_hour}</td>
                                            <td>
                                                <ButtonToolbar>
                                                    <OverlayTrigger placement="top"
                                                                    overlay={tooltip(assignment.driver_name,assignment.manager_name)}>
                                                        <Button className="badge btn btn-dark">
                                                            <i className="bi bi-info-circle"></i></Button>
                                                    </OverlayTrigger>
                                                </ButtonToolbar>
                                            </td>
                                            <td>{assignment.agency_name}</td>
                                            <td>
                                                <Link className="btn btn-sm btn-primary" to={`/manager/assignment/${assignment.id}/edit`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                    <span>Modif</span>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }

                                </tbody>}
                            </table>

                            {loading && <TableLoader width={300} height={350} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAssigngment;
